import React from "react";

import { useTranslation } from "react-i18next";
import Page from "../components/Page";
import Gallery from "../components/Gallery/Gallery";

const GalleryPage = () => {
  const { t } = useTranslation();
  return (
    <Page
      title="Lanchas la Gaviota's affiliate marketing program"
      description="Welcome to our affiliate marketing programs. The Gaviotas Associates Program helps content creators, publishers and bloggers monetize their traffic. Associates use easy link-building tools to direct their audience to their recommendations, and earn from qualifying purchases."
    >
      <Gallery />
    </Page>
  );
};

export default GalleryPage;
